// @ts-nocheck
import { useState } from 'react';
import Cookies from 'js-cookie';
import axiosClient from 'src/config';
import axiosClientEbay from 'src/configEbay';

interface Response<T> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
}

interface UseCustomApiHookProps<T> {
  response: Response<T> | null;
  loading: boolean;
  error: Error | null;
  getMiranda: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T, any> | undefined>;
  get: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T, any> | undefined>;
  post: (url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T, any> | undefined>;
  put: (url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T, any> | undefined>;
  del: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T, any> | undefined>;
}

function useCustomApiHook<T>(): UseCustomApiHookProps<T> {
  const [response, setResponse] = useState<Response<T> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const handleResponse = (res: AxiosResponse<T>) => {
    setResponse({
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
      config: res.config,
    });
    setLoading(false);
  };

  const handleError = (err: Error | unknown) => {
    setError(err as Error);
    setLoading(false);
  };

  const enrichConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
    const token = Cookies.get('token'); // Get token from cookies
    const authConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    };

    return authConfig;
  };

  const get = async (url: string, config?: AxiosRequestConfig) => {
    setLoading(true);
    try {
      const res = await axiosClient.get<T>(url, enrichConfig(config));
      handleResponse(res);

      return res;
    } catch (err) {
      handleError(err);
    }
  };

  const getMiranda = async (url: string, config?: AxiosRequestConfig) => {
    setLoading(true);
    try {
      const res = await axiosClientEbay.get<T>(url, enrichConfig(config));
      handleResponse(res);

      return res;
      
    } catch (err) {
      handleError(err);
    }
  };

  const post = async (url: string, data?: any, config?: AxiosRequestConfig) => {
    setLoading(true);
    try {
      const res = await axiosClient.post<T>(url, data, enrichConfig(config));
      handleResponse(res);

      return res;
    } catch (err) {
      handleError(err);
    }
  };

  const put = async (url: string, data?: any, config?: AxiosRequestConfig) => {
    setLoading(true);
    try {
      const res = await axiosClient.put<T>(url, data, enrichConfig(config));
      handleResponse(res);

      return res;

    } catch (err) {
      handleError(err);
    }
  };

  const del = async (url: string, config?: AxiosRequestConfig) => {
    setLoading(true);
    try {
      const res = await axiosClient.delete<T>(url, enrichConfig(config));
      handleResponse(res);
      
      return res;
    } catch (err) {
      handleError(err);
    }
  };

  return {
    response,
    loading,
    error,
    get,
    getMiranda,
    post,
    put,
    del,
  };
}

export default useCustomApiHook;
